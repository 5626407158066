<template>
<div>
  <div class="container-fluid">
    <PageTitle />
    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :idKey="idKey"
        :dataListing.sync="data"
        :fields="fields"

        :no-btn-mass="true"
        :no-multi-select="true"
      > 
        <template #filters>
          <b-col md=4>
            <VSelect 
              v-model="filter.level" 
              placeholder="-- Semua Level --"
              :options="mrBranchLevel" 
              :reduce="v=>v.value"
              @input="doFilter"
            />
          </b-col>
        </template>
        <template #muk_is_active="data">
          <LabelStatus :status="data.scope.item[statusKey]" />
        </template>
        <template #muk_level="data">
          {{ data.scope.item.mbl_name.charAt(0).toUpperCase() + data.scope.item.mbl_name.slice(1) }}
        </template>
        <template #aksi="data">
          <div v-if="!moduleRole('ViewOnly')" class="btn-toolbar">
            <b-dropdown no-flip>
              <template #button-content>
                <i class="icon-menu"></i>
              </template>
              <b-dropdown-item :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                Ubah Data
              </b-dropdown-item>
              <b-dropdown-item @click="doDelete(data.scope.item.muk_code, data.scope.item)">Hapus</b-dropdown-item>
            </b-dropdown>
            <b-dropdown 
              no-flip 
              variant="info" 
              class="ml10" toggle-class="absolute-button"
            >
              <template #button-content>
                <i class="ti-exchange-vertical" v-b-tooltip.hover 
              title="Ganti Status"></i>
              </template>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==1}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 1)"
              >
                Active
              </b-dropdown-item>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==2}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 2)"
              >
                Inactive
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <span v-else>-</span>
        </template>
      </card-list>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Unit Kerja Level <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                      v-model="row.muk_level" 
                      placeholder="-- Semua Level --"
                      :options="mrBranchLevel" 
                      :reduce="v=>v.value"
                      :disabled="!!row[idKey]"
                    />
                  <VValidate 
                    name="Kantor Level" 
                    v-model="row.muk_level" 
                    :rules="mrValidation.muk_level" 
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col md=6>
                <b-form-group>
                  <template #label>
                    Sorting <span class="text-danger mr5">*</span>
                  </template>
                  <b-input
                    type="text"
                    v-model="row.muk_sort"
                  />
                  <VValidate 
                    name="Sorting" 
                    v-model="row.muk_sort" 
                    :rules="mrValidation.muk_sort" 
                  />
                </b-form-group>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md=3>
                <b-form-group>
                  <template #label>
                    Kode Unit Kerja <span class="text-danger mr5">*</span>
                  </template>
                  <b-input
                    type="text"
                    v-model="row.muk_code"
                    :readonly="row.muk_create_date"
                  />
                  <VValidate 
                    name="Kode Unit Kerja" 
                    v-model="row.muk_code" 
                    :rules="mrValidation.muk_code" 
                  />
                </b-form-group>
              </b-col>


            </b-row>

            <b-row>
              <b-col md=6>
                <b-form-group>
                  <template #label>
                    Unit Kerja <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-textarea
                    id="textarea"
                    v-model="row.muk_description"
                    placeholder="Tulis nama unit kerja..."
                    rows="3"
                  ></b-form-textarea>
                  <VValidate 
                    name="Unit Kerja" 
                    v-model="row.muk_description" 
                    :rules="mrValidation.muk_description" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="6"
              >
                <b-form-group>
                  <template #label>
                    Status <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="row[statusKey]"
                    name="radio-group-2"
                  >
                    <b-form-radio value=1>Active</b-form-radio>
                    <b-form-radio value=2>Inactive</b-form-radio>
                  </b-form-radio-group>
                  <VValidate 
                    name="Status" 
                    v-model="row[statusKey]" 
                    :rules="mrValidation[statusKey]" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'

export default{
  extends:GlobalVue,
  components:{PageTitle, CardList},
  data(){
    return {
      idKey:'muk_id',
      statusKey:'muk_is_active',
      fields:[
        { key: "select", label: "" },
        { key:'number', label:'#' },
        { key:'muk_code', label:'Kode Unit Kerja' },
        { key: 'muk_level', label: "Unit Kerja Level", is_custom: true },
        /* { key:'muk_sort', label:'Urutan' }, */
        { key:'muk_description', label:'Unit Kerja' },
        { key:'muk_is_active', label:'Status', is_custom:true },
        { key:'aksi', is_custom:true, style:{width:'170px'} },
      ],
      mrBranchLevel: []
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'row.muk_code'(v){
      this.row.muk_code = v.toUpperCase()
    }
  }
}
</script>